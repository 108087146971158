
.score-container {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.tab {
    .name {
        margin: 0 10px 0 0;
    }
}
